import { ModelLog } from './../models/model-log.model';
import { AllocationTimeLine } from '@core/models/allocation-time-line.model';
import { Allocation } from '@core/models/allocation.model';
import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { OrderLine } from '@core/models/order-line.model';
import { map } from 'rxjs/operators';
import { AvailabilityData } from '@core/models/check-availability.model';

@Injectable({
  providedIn: 'root',
})
export class OrderLineService extends ApiService<OrderLine> {
  protected endpoint = 'order-line';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public checkOrderLine(data: OrderLine, options?: ListOptions): Observable<AvailabilityData> {
    let url = `/${this.endpoint}/check`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post<ApiSingleResult<AvailabilityData>>(url, data).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public setStock(allocationId: string, data: any): Observable<string> {
    const url = `/${this.endpoint}/${allocationId}/stock-finder`;

    return this.apiHttp.patch(url, data);
  }

  public getTheLastAllocatedStock(orderLineId: string, options?: ListOptions): Observable<Allocation[]> {
    let url = `/${this.endpoint}/${orderLineId}/allocated-stock`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiSingleResult<Allocation[]>>(url).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public getAvailableStock(orderLineId: string, filtersValue: object): Observable<Allocation[]> {
    const url = `/${this.endpoint}/${orderLineId}/available-stock`;

    return this.apiHttp.post<ApiSingleResult<Allocation[]>>(url, filtersValue).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public switchAllocation(orderLineId: string): Observable<ApiSingleResult<OrderLine>> {
    return this.apiHttp.patch(`/${this.endpoint}/${orderLineId}/switch-allocation`, {});
  }

  public getAllocationTimeLine(orderLineId: string, options?: ListOptions): Observable<AllocationTimeLine> {
    return this.get(`${orderLineId}/allocation-timeline`, options);
  }

  public getAllocationHistory(orderLineId: string, options?: ListOptions): Observable<{ [key: string]: ModelLog[] }[]> {
    return this.get(`${orderLineId}/allocation-history`, options);
  }

  public getClientOrderLines(
    data: { client_id: string; batch_id: string },
    options?: ListOptions,
  ): Observable<OrderLine[]> {
    let url = `/${this.endpoint}/client-order-lines`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post<ApiSingleResult<OrderLine[]>>(url, data).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }
}
